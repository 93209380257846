import React, {useContext} from "react";
import {GlobalContext} from "../GlobalContext";
import {CapsuleListDesktop} from "./CapsuleListDesktop";
import {CapsuleListMobile} from "./CapsuleListMobile";

export function CapsuleList() {
  const {desktopMode} = useContext(GlobalContext)
  const capsules = [
    {
      name: 'Одноместная капсула, нижний ярус',
      image: '9D9A1844.jpg',
      description: 'Все капсулы отделяются удобными рулонными шторами типа black-out, имеют качественную шумоизоляцию, вентиляцию, оборудованы удобными матрасами, полочками для мелких вещей, электрическими розетками и крючками для одежды. Размер спального места 2,0*1,0\xA0м. Стандартная вместимость: 1 взрослый. Количество номеров: 15'
    },
    {
      name: 'Двухместная капсула, нижний ярус',
      image: '9D9A1637.jpg',
      description: 'Все капсулы отделяются удобными рулонными шторами типа black-out, имеют качественную шумоизоляцию, вентиляцию, оборудованы удобными матрасами, полочками для мелких вещей, электрическими розетками и крючками для одежды. Размер спального места 2,0*1,0\xA0м. Стандартная вместимость: 1 взрослый. Количество номеров: 15'
    },
    {
      name: 'Одноместная капсула, верхний ярус',
      image: '9D9A1500.jpg',
      description: 'Все капсулы отделяются удобными рулонными шторами типа black-out, имеют качественную шумоизоляцию, вентиляцию, оборудованы удобными матрасами, полочками для мелких вещей, электрическими розетками и крючками для одежды. Размер спального места 2,0*1,4\xA0м. Стандартная вместимость: 2 взрослых. Возможно одноместное размещение. Количество номеров: 4'
    },
    {
      name: 'Двухместная капсула, верхний ярус',
      image: '9D9A1645.jpg',
      description: 'Все капсулы отделяются удобными рулонными шторами типа black-out, имеют качественную шумоизоляцию, вентиляцию, оборудованы удобными матрасами, полочками для мелких вещей, электрическими розетками и крючками для одежды. Размер спального места 2,0*1,4\xA0м. Стандартная вместимость: 2 взрослых. Возможно одноместное размещение. Количество номеров: 4'
    },
    {
      name: 'Отдельный двухместный номер, с широкой двухместной кроватью',
      image: 'IMG_20211218_150350.jpg',
      description: 'Номер оборудован телевизором, шкафом для одежды, комодом для личных вещей, кондиционером. Размер спального места 2,0*1,6\xA0м. Стандартная вместимость: 2 взрослых. Возможно одноместное размещение. Количество номеров: 1'
    }
  ]

  return desktopMode
    ? <CapsuleListDesktop capsules={capsules}/>
    : <CapsuleListMobile capsules={capsules}/>
}