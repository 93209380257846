import React, {useReducer} from "react";
import {capsuleReducer} from "../capsuleReducer";

export function CapsuleListMobile({capsules}) {
  const [{currentIndex}, dispatcher] = useReducer(capsuleReducer, {count: capsules.length, currentIndex: 0})
  const capsule = capsules[currentIndex]

  return (
    <div id='capsule'>
      <img src={`images/${capsule.image}`} alt={capsule.name.toUpperCase()} loading='lazy'/>
      <i className='material-icons left'  onClick={() => dispatcher('LEFT')}>chevron_left</i>
      <i className='material-icons right' onClick={() => dispatcher('RIGHT')}>chevron_right</i>
      <h5>{capsule.name.toUpperCase()}</h5>
      <p>{capsule.description}</p>
    </div>
  )
}