import React from "react";

export function CapsuleListDesktop({capsules}) {
  return (
    <div id='capsule-list'>
      {capsules.map((capsule, index) => {
        const right = index % 2 === 0
        return <div key={index} className='capsule'>
          <img src={`images/${capsule.image}`} alt={capsule.name.toUpperCase()} className={right ? 'right' : 'left'} loading='lazy'/>
          <h5 className={right ? 'right-align' : 'left-align'}>{capsule.name.toUpperCase()}</h5>
          <p className={right ? 'right-align' : 'left-align'}>{capsule.description}</p>
        </div>
      })}
    </div>
  )
}