export function capsuleReducer(state, direction) {
  switch (direction) {
    case 'LEFT':
      return {
        count: state.count,
        currentIndex: state.currentIndex === 0 ? state.count - 1 : state.currentIndex - 1
      }
    case 'RIGHT':
      return {
        count: state.count,
        currentIndex: state.currentIndex === state.count - 1 ? 0 : state.currentIndex + 1
      }
    default: {
    }
  }
}