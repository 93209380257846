import React from "react";
import {Header} from "./components/Header";
import {Home} from "./pages/Home";
import {GlobalContext} from "./GlobalContext";

export function App() {
  const desktopMode = navigator.userAgentData && !navigator.userAgentData.mobile

  return (
    <GlobalContext.Provider value={{desktopMode: desktopMode}}>
      <div id='App'>
        <Header/>
        <main>
          <Home/>
        </main>
      </div>
    </GlobalContext.Provider>
  );
}