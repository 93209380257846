import React from "react";

export function Contacts() {
  return (
    <div id='contacts'>
      <label htmlFor='address'>Адрес: </label>
      <span id='address'>г.Волгоград, ул. Ленина, 9к1</span><br/>
      <label htmlFor='phone'>Телефон: </label>
      <a href='tel:+7(988)044-94-12' id='phone'>+7(988)044-94-12</a><br/>
      <label htmlFor='email'>Email: </label>
      <a href='mailto:info@kapsula-vremeni.ru' id='email'>info@kapsula-vremeni.ru</a>
    </div>
  )
}