import React from "react";
import M from 'materialize-css';
import {createRef, useEffect} from "react";

export function ImageCarousel() {
  const imageIds = ['9D9A1547.jpg', '9D9A1752.jpg', '9D9A1961.jpg', '9D9A2007.jpg', '9D9A2095.jpg', '9D9A1630.jpg', '9D9A1861.jpg', '9D9A1971.jpg', '9D9A2027.jpg']

  const carouselRef = createRef()
  useEffect(() => {
    const instance = M.Carousel.init(carouselRef.current, {
      numVisible: 3,
      fullWidth: false,
    })
    setInterval(() => {
      instance.next()
    }, 5000)
  }, [])

  return (
    <div id='image-carousel' className='carousel' ref={carouselRef}>
      {imageIds.map(id => (
        <img key={id} className='carousel-item' src={`images/${id}`} alt='Фото "Капсулы времени"' loading='lazy'/>
      ))}
    </div>
  )
}