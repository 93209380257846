import React from "react";
import {ImageCarousel} from "../components/ImageCarousel";
import {BookingModule} from "../components/BookingModule";
import {Map} from "../components/Map";
import {Contacts} from "../components/Contacts";
import {MainDescription} from "../components/MainDescription";
import {CapsuleList} from "../components/CapsuleList";

export function Home() {
  return (
    <div className='container content'>
      <MainDescription/>
      <BookingModule/>
      <CapsuleList/>
      <ImageCarousel/>
      <Map/>
      <Contacts/>
    </div>
  )
}