import React from "react";

export function Map() {
  const ymaps = window.ymaps
  const position = [48.705131, 44.512328]

  ymaps.ready(() => {
    const map = new ymaps.Map('map', {
      center: position,
      zoom: 17
    })
    const geoObject = new ymaps.GeoObject({
      geometry: {
        type: "Point",
        coordinates: position
      }
    })
    map.geoObjects.add(geoObject)
  })

  return (
    <div id='map'></div>
  )
}