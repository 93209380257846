import React from "react";

export function MainDescription() {
  return (
    /*<p id='main-description'>

    </p>*/
    <div id='main-description'>
      <span>Уютный гостеприимный капсульный хостел в центре г. Волгограда, </span><br className='mobile-hidden'/>
      <span>в пешей доступности от транспортных развязок, ж/д вокзала, </span><br className='mobile-hidden'/>
      <span>основных культурных и исторических достопримечательностей. </span><br/>
      <span><i>"Капсула&nbsp;времени"</i> — это 38 номеров уютных и просторных кроватей-капсул, </span><br className='mobile-hidden'/>
      <span>где вы будете чувствовать себя как дома.</span>
    </div>
  )
}