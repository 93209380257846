import React, {useContext, useEffect} from 'react';
import {mainColor} from "../constants";
import {GlobalContext} from "../GlobalContext";

export function BookingModule() {
  const {desktopMode} = useContext(GlobalContext)
  const bnovoWidgetParams = {
    type: desktopMode ? 'horizontal' : 'vertical',
    uid: 'a9b7d213-ae43-4cb7-9485-703e54e9ac6c',
    lang: 'ru',
    currency: 'RUB',
    width: '100%',
    width_mobile: '100%',
    background: '#ffffff',
    background_mobile: '#ffffff',
    bg_alpha: '100',
    bg_alpha_mobile: '100',
    border_color_mobile: '#C6CAD3',
    padding: '24',
    padding_mobile: '24',
    border_radius: '8',
    font_type: 'inter',
    title_color: '#242742',
    title_color_mobile: '#242742',
    title_size: '22',
    title_size_mobile: '22',
    inp_color: '#242742',
    inp_bordhover: '#dedfe3',
    inp_bordcolor: '#BCBCBC',
    inp_alpha: '100',
    btn_background: mainColor,
    btn_background_over: '#1850D2',
    btn_textcolor: '#FFFFFF',
    btn_textover: '#FFFFFF',
    btn_bordcolor: mainColor,
    btn_bordhover: '#1850D2',
    adults_default: '1',
    cancel_color: '#FFFFFF',
    switch_mobiles_width: '800',
  }
  useEffect(() => {
    const widget = window.Bnovo_Widget

    widget.init(() => {
      widget.open('booking-module', bnovoWidgetParams)
    })
  })

  return (
    <div id='booking-module'/>
  )
}