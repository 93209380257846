import React from "react";

export function Header() {
  return (
    <div className='header'>
      <img src={`images/header.png`} alt='Капсула времени'/>
      <div id='header-contacts'>
        <label htmlFor='address'>Адрес: </label>
        <span id='address'>г.Волгоград, ул. Ленина, 9к1</span><br/>
        <label htmlFor='phone'>Телефон: </label>
        <a href='tel:+7(988)044-94-12' id='phone'>+7(988)044-94-12</a>
      </div>
      <iframe title='yandex-score' src="https://yandex.ru/sprav/widget/rating-badge/217977363520?type=award"></iframe>
    </div>
  )
}